import { useGlobalContext } from '../../context/context';
import { useEffect, useState } from 'react';
import Loader from '../../components/loaders/Loader';

const WithdrawalHistory = () => {
  const {
    setIsUser,
    setUserToken,
    userToken,
    baseURL,
    resetPage,
    userDetails,
    fetchUser,
  } = useGlobalContext();
  const [allTransactions, setAllTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const { currency } = userDetails;

  useEffect(() => {
    resetPage(`Withdrawal History`);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [userToken]);

  // RESTORE USER DASHBOARD LINKS AFTER REFRESH
  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  // GET USER TOKEN FROM LOCAL STORAGE
  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));
  }, [setUserToken]);

  // FETCH ALL TRANSACTIONS FUNCTION
  const fetchAllTransactions = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/withdrawal/all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      setLoading(false);
      setAllTransactions(data.allWithdrawals);
    } catch (error) {
      setLoading(false);
    }
  };

  // FETCH ALL USER TRANSACTIONS ONLY WHEN USER TOKEN HAS BEEN LOADED FROM THE LOCAL STORAGE
  useEffect(() => {
    setTimeout(() => {
      setTrigger(1);
      if (userToken) {
        fetchAllTransactions();
      }
    }, 500);
    // eslint-disable-next-line
  }, [trigger]);

  return (
    <section className='withdrawal-history'>
      <div className='history-block'>
        {/* TABLE CONTAINING USER INFORMATION GOTTEN FROM THE API */}
        <h1>Withdrawal History</h1>
        {loading ? (
          <div className='loader'>
            <Loader />
          </div>
        ) : allTransactions ? (
          <div className='table'>
            {allTransactions.reverse().map((transaction) => {
              const {
                amount,
                createdAt,
                reference,
                status,
                _id: id,
                charges,
              } = transaction;

              return (
                <div className='table-data' key={id}>
                  <div className='div'>
                    <h4>Date</h4>
                    <p>{new Date(createdAt).toLocaleDateString()}</p>
                  </div>
                  <div className='div'>
                    <h4>Narration</h4>
                    <p>withdrawal</p>
                  </div>
                  <div className='div'>
                    <h4>Reference</h4>
                    <p>{reference}</p>
                  </div>
                  <div className='div'>
                    <h4>Amount</h4>
                    <p>
                      {currency ? currency : `$`}
                      {amount}
                    </p>
                  </div>
                  <div className='div'>
                    <h4>Transaction Fee</h4>
                    <p>
                      {currency ? currency : `$`}
                      {charges === 0
                        ? 0
                        : charges > 0
                        ? (amount * charges).toLocaleString(`en-US`)
                        : null}
                    </p>
                  </div>
                  <div className='div'>
                    <h4>Status</h4>
                    <p
                      className={
                        status === `pending`
                          ? `pending`
                          : status === `approved`
                          ? `approved`
                          : status === `failed`
                          ? `failed`
                          : null
                      }
                    >
                      {status}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className='no-transaction'>
            <h3>No transaction history</h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default WithdrawalHistory;
