import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ButtonLoader from '../../components/loaders/ButtonLoader';
import SuccessfulRegistration from '../../components/modals/SuccessfulRegistration';
import { useGlobalContext } from '../../context/context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import CurrencyList from 'currency-list';
import axios from 'axios';

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [regError, setRegError] = useState(``);

  const currencies = CurrencyList.getAll(`en_US`);

  const newCurrencies = Object.values(currencies).map((obj) => ({
    name: obj.name,
    symbol: obj.symbol,
  }));

  const {
    showModal,
    setShowModal,
    setSeedPhrase,
    baseURL,
    resetPage,
    revealPassword,
  } = useGlobalContext();

  const formik = useFormik({
    initialValues: {
      name: ``,
      email: ``,
      password: ``,
      zipCode: ``,
      address: ``,
      countryOfResidence: ``,
      investmentGoal: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),

      name: Yup.string().required(`Fullname is required`),

      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .required('Password is required'),

      zipCode: Yup.string().required('Zip code is required'),
      countryOfResidence: Yup.string().required(
        'Country of residence is required'
      ),

      address: Yup.string()
        .min(5, 'Address must be at least 5 characters long')
        .required('Address is required'),

      investmentGoal: Yup.string().required('Please select an investment goal'),
    }),
    onSubmit() {
      register();
    },
  });

  // FUNCTION TO REGISTER USER
  const register = async () => {
    const { countryOfResidence } = formik.values;

    const currency = newCurrencies.filter((cur) => {
      if (
        countryOfResidence.toLocaleLowerCase() === `united states of america`
      ) {
        return cur.name.toLocaleLowerCase().includes(`us dollars`);
      } else {
        return cur.name
          .toLocaleLowerCase()
          .includes(countryOfResidence.toLocaleLowerCase());
      }
    });

    setIsLoading(true);
    setRegError(``);
    try {
      const { data } = await axios.post(`${baseURL}/auth/register`, {
        name: formik.values.name,
        email: formik.values.email.toLowerCase(),
        password: formik.values.password,
        zipCode: formik.values.zipCode,
        address: formik.values.address,
        countryOfResidence:
          formik.values.countryOfResidence.toLocaleLowerCase(),
        currency: currency[0] ? currency[0].symbol : `$`,
        investmentGoal: formik.values.investmentGoal,
      });
      setIsLoading(false);
      setShowModal(true);
      setSeedPhrase(data.seedPhrase);
    } catch (error) {
      console.log(error);
      setRegError(error.response.data.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    resetPage(`Create Account`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='sign-up'>
        <article className='sign-up-modal'>
          <h1>Create Personal Account</h1>
          <form onSubmit={formik.handleSubmit} className='sign-up-form'>
            {/* PERSONAL EMAIL */}
            <div className='form-control'>
              <label
                htmlFor='email'
                className={
                  formik.touched.email && formik.errors.email ? `red` : null
                }
              >
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : `Personal Email`}
              </label>
              <br />
              <input
                type='email'
                placeholder='johnDoe@email.com'
                id='email'
                className='input'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* FULL NAME */}
            <div className='form-control'>
              <label
                htmlFor='full-name'
                className={
                  formik.touched.name && formik.errors.name ? `red` : null
                }
              >
                {formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : `Full Name`}
              </label>
              <input
                type='text'
                placeholder='John Doe'
                id='full-name'
                className='input'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* PASSWORD */}
            <div className='form-control'>
              <label
                htmlFor='password'
                className={
                  formik.touched.password && formik.errors.password
                    ? `red`
                    : null
                }
              >
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : `Password`}
              </label>
              <div className='password-container'>
                <input
                  type='password'
                  placeholder='**********'
                  id='password'
                  className={formik.errors.password ? `input red` : 'input'}
                  name='password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <p
                  className='reveal-btn'
                  onClick={() => {
                    revealPassword();
                    setPasswordVisible(!passwordVisible);
                  }}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </p>
              </div>
            </div>

            {/* INVESTMENT GOAL */}
            <div className='form-control'>
              <label
                htmlFor='investment-goal'
                className={
                  formik.touched.investmentGoal && formik.errors.investmentGoal
                    ? `red`
                    : null
                }
              >
                {formik.touched.investmentGoal && formik.errors.investmentGoal
                  ? formik.errors.investmentGoal
                  : `Investment Goal`}
              </label>
              <select
                name='investmentGoal'
                id='investment-goal'
                value={formik.values.investmentGoal}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option hidden>Select Investment Goal</option>
                <option value='none'>None</option>
                <option value='Investing for Retirement'>
                  Investing for Retirement
                </option>
                <option value='Investing to buy house'>
                  Investing to buy house
                </option>
                <option value='A Career Change'>A Career Change</option>
                <option value='Starting a business'>Starting a business</option>
              </select>
            </div>

            {/* COUNTRY OF RESIDENCE */}
            <div className='form-control'>
              <label
                htmlFor='countryOfResidence'
                className={
                  formik.touched.countryOfResidence &&
                  formik.errors.countryOfResidence
                    ? `red`
                    : null
                }
              >
                {formik.touched.countryOfResidence &&
                formik.errors.countryOfResidence
                  ? formik.errors.countryOfResidence
                  : `Country of Residence`}
              </label>
              <input
                type='text'
                placeholder='Eg. United States of america'
                id='countryOfResidence'
                className='input'
                name='countryOfResidence'
                value={formik.values.countryOfResidence}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* ADDRESS */}
            <div className='form-control'>
              <label
                htmlFor='address'
                className={
                  formik.touched.address && formik.errors.address ? `red` : null
                }
              >
                {formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : `Address`}
              </label>
              <input
                type='text'
                placeholder='No 2, Joy street, NJ, USA'
                id='address'
                className='input'
                name='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* ZIP CODE */}
            <div className='form-control'>
              <label
                htmlFor='zipCode'
                className={
                  formik.touched.zipCode && formik.errors.zipCode ? `red` : null
                }
              >
                {formik.touched.zipCode && formik.errors.zipCode
                  ? formik.errors.zipCode
                  : `Zip Code`}
              </label>
              <input
                type='text'
                placeholder='4354543'
                id='zipCode'
                className='input'
                name='zipCode'
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {regError ? <p className='registration-error'>{regError}</p> : null}

            {/* CHECKBOX FOR TERMS AND CONDITIONS */}
            <div className='conditions'>
              <div className='terms-condition'>
                <input
                  type='checkbox'
                  name='termsCondition'
                  id='terms-check'
                  required
                />
                <label htmlFor='terms-condition'>
                  I agree to <b>Malroc AI Trades's</b>
                  <Link href='/terms-of-use'> Terms of Service</Link> and
                  <Link href='/privacy-policy'> Privacy Policy</Link>
                </label>
              </div>
            </div>

            {/* EXISTING ACCOUNT/SUBMIT BUTTON DIV */}
            <div className='account-submitBtn'>
              <Link to='/sign-in'>
                <p>Already have an account?</p>
              </Link>
              <div className='btn'>
                <button className='gold' type='submit'>
                  Create account
                </button>
                {isLoading && <ButtonLoader />}
              </div>
            </div>
          </form>
        </article>
        {showModal && <SuccessfulRegistration />}
      </section>
    </>
  );
};

export default SignUp;
